import React, { PureComponent } from "react";
import { Grid, Hidden } from "@material-ui/core";

class TypeRanges extends PureComponent {
  render() {
    const { ...data } = this.props;

    let refs_with_year = data.references.filter((ref) => ref.years);
    let refs_without_year = data.references.filter((ref) => !ref.years);

    let ref_html = null;
    let showed = false;

    if (refs_with_year.length > 0) {
      for (let ref of refs_with_year) {
        if (ref.sex === data.sex || ref.sex === "unisex") {
          if (
            data.years_old >= ref.years[0] &&
            data.years_old <= ref.years[1]
          ) {
            ref_html = (
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12}>
                  {ref.init != null
                    ? ref.init.text + " " + ref.init.value + " " + data.unity
                    : ""}
                </Grid>
                {ref.ranges.map((range, i) => {
                  return (
                    <Grid key={i} item xs={12}>
                      {range.text +
                        " " +
                        range.values[0] +
                        " - " +
                        range.values[1] +
                        " " +
                        data.unity}{" "}
                    </Grid>
                  );
                })}
                <Grid item xs={12}>
                  {ref.end != null
                    ? ref.end.text + " " + ref.end.value + " " + data.unity
                    : ""}
                </Grid>
              </Grid>
            );
            showed = true;
          }
        }
      }
    }

    if (!showed) {
      for (let ref of refs_without_year) {
        if (ref.sex === data.sex || ref.sex === "unisex") {
          ref_html = (
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                {ref.init != null
                  ? ref.init.text + " " + ref.init.value + " " + data.unity
                  : ""}
              </Grid>
              {ref.ranges.map((range, i) => {
                return (
                  <Grid key={i} item xs={12}>
                    {range.text +
                      " " +
                      range.values[0] +
                      " - " +
                      range.values[1] +
                      " " +
                      data.unity}{" "}
                  </Grid>
                );
              })}
              <Grid item xs={12}>
                {ref.end != null
                  ? ref.end.text + " " + ref.end.value + " " + data.unity
                  : ""}
              </Grid>
            </Grid>
          );
          showed = true;
        }
      }
    }

    return (
      <Grid container xs={12} className="boder_bottom_text" spacing={1}>
        <Grid item md={4} xs={12}>
          <strong>{data.name}</strong>
        </Grid>
        <Hidden mdUp>
          <Grid item md={4} xs={4}>
            Resultado:
          </Grid>
        </Hidden>
        <Grid item md={4} xs={8}>
          <strong>{data.value}</strong>
        </Grid>
        <Hidden mdUp>
          <Grid item md={4} xs={4}>
            Referencias:
          </Grid>
        </Hidden>
        <Grid item md={4} xs={8} container>
          {ref_html}
        </Grid>
      </Grid>
    );
  }
}

export default TypeRanges;
