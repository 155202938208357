import React, { PureComponent } from "react";
import { Grid, Hidden } from "@material-ui/core";

class TypeLimit extends PureComponent {
  render() {
    const { ...data } = this.props;
    let refs_with_year = data.references.filter((ref) => ref.years);
    let refs_without_year = data.references.filter((ref) => !ref.years);

    let ref_html = null;
    let showed = false;

    if (refs_with_year.length > 0) {
      for (let ref of refs_with_year) {
        if (ref.sex === data.sex || ref.sex === "unisex") {
          if (
            data.years_old >= ref.years[0] &&
            data.years_old <= ref.years[1]
          ) {
            ref_html = <span>{ref.limit + " " + data.unity}</span>;
            showed = true;
          }
        }
      }
    }

    if (!showed) {
      for (let ref of refs_without_year) {
        if (ref.sex === data.sex || ref.sex === "unisex") {
          ref_html = <span>{ref.limit + " " + data.unity}</span>;
          showed = true;
        }
      }
    }

    return (
      <Grid
        item
        xs={12}
        container
        className="boder_bottom_text"
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item md={4} xs={12}>
          <strong>{data.name}</strong>
        </Grid>
        <Hidden mdUp>
          <Grid item md={4} xs={4}>
            Resultado:
          </Grid>
        </Hidden>

        <Grid item md={4} xs={8}>
          <strong>{data.value}</strong>
        </Grid>
        <Hidden mdUp>
          <Grid item md={4} xs={4}>
            Referencia:
          </Grid>
        </Hidden>

        <Grid item md={4} xs={8}>
          Hasta &nbsp;
          {ref_html}
        </Grid>
      </Grid>
    );
  }
}

export default TypeLimit;
