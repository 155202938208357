import React, { PureComponent } from "react";
import { Grid, Hidden } from "@material-ui/core";

class TypeGeneric extends PureComponent {
  render() {
    const { ...data } = this.props;

    let refs_with_year = data.references.filter((ref) => ref.years);
    let refs_without_year = data.references.filter((ref) => !ref.years);

    let ref_html = null;
    let showed = false;

    if (refs_with_year.length > 0) {
      for (let ref of refs_with_year) {
        if (ref.sex === data.sex || ref.sex === "unisex") {
          if (
            data.years_old >= ref.years[0] &&
            data.years_old <= ref.years[1]
          ) {
            const unity = data.unity ? data.unity : "";
            ref_html = <span>{ref.generic + " " + unity}</span>;
            showed = true;
          }
        }
      }
    }

    if (!showed) {
      for (let ref of refs_without_year) {
        if (ref.sex === data.sex || ref.sex === "unisex") {
          const unity = data.unity ? data.unity : "";

          ref_html = <span>{ref.generic + " " + unity}</span>;
          showed = true;
        }
      }
    }

    console.log("xxx", data);

    return (
      <Grid container item xs={12} className="boder_bottom_text" spacing={2}>
        <Grid item md={4} xs={12}>
          <strong>{data.name} </strong>
        </Grid>
        <Hidden mdUp>
          <Grid item md={4} xs={4}>
            Resultado:
          </Grid>
        </Hidden>

        <Grid item md={4} xs={8}>
          <strong>{data.value}</strong>
        </Grid>
        <Hidden mdUp>
          <Grid item md={4} xs={4}>
            Referencia:
          </Grid>
        </Hidden>
        <Grid item md={4} xs={8}>
          {ref_html}
        </Grid>
      </Grid>
    );
  }
}

export default TypeGeneric;
