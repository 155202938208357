import React, { PureComponent } from "react";
import { Grid, Hidden } from "@material-ui/core";

class TypeTextPosNeg extends PureComponent {
  render() {
    const { ...data } = this.props;
    return (
      <Grid container xs={12} item className="boder_bottom_text" spacing={2}>
        <Grid item md={4} xs={12}>
          <strong>{data.name}</strong>
        </Grid>
        <Hidden mdUp>
          <Grid item md={4} xs={4}>
            Resultado:
          </Grid>
        </Hidden>
        <Grid item md={4} xs={8}>
          <strong>{data.value}</strong>
        </Grid>
      </Grid>
    );
  }
}

export default TypeTextPosNeg;
