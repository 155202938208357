import { useEffect, useState } from "react";
import {
  Grid,
  Divider,
  makeStyles,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import config from "../../config/data";
import TestType from "../../components/TypeTests/TestType";
import Header from "../../components/Header";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  logo: {
    marginTop: 30,
    marginBottom: 20,
    height: "4.688rem",
  },
  value: {
    color: "#1a1a1a",
    fontWeight: 600,
    fontSize: "1.5rem",
  },
  label: {
    color: "#1a1a1a",
    fontWeight: 400,
    fontSize: "1rem",
  },
  group: {
    paddingTop: "40px !important",
    color: "#006AFF",
    fontSize: "2.25rem",
  },
  border: {
    paddingBottom: "5px",
    marginTop: "5px",
    borderBottom: "1px solid #d9d9d9",
  },
  headerTitle: {
    color: "#006AFF",
    marginTop: 20,
  },
  loader: {
    paddingTop: 100,
  },
}));

const TestVerify = () => {
  let { testCode } = useParams();
  const [data, setData] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const classes = useStyles();

  const getData = async (testCode) => {
    try {
      const url = config.api_url + "/verify/" + testCode;
      const res = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: new Headers({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        }),
      });

      if (res.ok) {
        const data = await res.json();
        setData(data);
      } else if (res.status === 404) {
        setShowMessage(true);
      } else {
        setErrorApi(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData(testCode);
  }, []);

  if (data === null && showMessage) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item className={classes.loader}>
          <Typography variant="h4">
            No existe la prueba para el codigo "{testCode}"
          </Typography>
        </Grid>
      </Grid>
    );
  }

  if (data === null && errorApi) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item className={classes.loader}>
          <Typography variant="h4">
            Error de sistema intentelo de nuevo por favor
          </Typography>
        </Grid>
      </Grid>
    );
  }

  if (data === null) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item className={classes.loader}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  if (data && data.status !== "complete") {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item className={classes.loader}>
          <Typography variant="h4">
            La Orden {testCode} se encuentra en proceso
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      <Header data={data} testCode={testCode} status={data.status} />
      <Divider />

      <Grid
        item
        xs={12}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Typography className={classes.headerTitle} variant="h4">
          Detalle del Examen
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {data &&
          data.test_values_by_categories &&
          data.test_values_by_categories.blood &&
          data.test_values_by_categories.blood.map((group, index) => {
            return (
              <Grid item container xs={12} spacing={3}>
                <Grid item xs={12} className={classes.group}>
                  {group.name}
                </Grid>
                {group.tests.map((test) => {
                  if (test) {
                    return (
                      <Grid
                        item
                        xs={12}
                        container
                        key={test.test_id}
                        spacing={3}
                        className={classes.border}
                      >
                        <TestType
                          key={test.id}
                          years_old={data.patient.years_old}
                          sex={data.patient.sex}
                          {...test}
                          test_id={testCode}
                        />
                      </Grid>
                    );
                  } else {
                    return (
                      <div
                        className="cell medium-12 boder_bottom"
                        Key={index + "x"}
                      >
                        <div> falta datos de referencia para una prueba</div>
                      </div>
                    );
                  }
                })}
              </Grid>
            );
          })}

        {data &&
          data.test_values_by_categories &&
          data.test_values_by_categories.serum &&
          data.test_values_by_categories.serum.map((group, index) => {
            return (
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} className={classes.group}>
                  {group.name}
                </Grid>
                {group.tests.map((test, index) => {
                  if (test) {
                    return (
                      <Grid
                        item
                        xs={12}
                        container
                        key={test.test_id}
                        spacing={3}
                        className={classes.border}
                      >
                        <TestType
                          key={test.id}
                          years_old={data.patient.years_old}
                          sex={data.patient.sex}
                          {...test}
                          test_id={testCode}
                        />
                      </Grid>
                    );
                  } else {
                    return (
                      <div
                        className="cell medium-12 boder_bottom"
                        Key={index + "x"}
                      >
                        <div> falta datos de referencia para una prueba</div>
                      </div>
                    );
                  }
                })}
              </Grid>
            );
          })}

        {data &&
          data.test_values_by_categories &&
          data.test_values_by_categories.urine &&
          data.test_values_by_categories.urine.map((group, index) => {
            return (
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} className={classes.group}>
                  {group.name}
                </Grid>
                {group.tests.map((test, index) => {
                  if (test) {
                    return (
                      <Grid
                        item
                        xs={12}
                        container
                        key={test.test_id}
                        spacing={3}
                        className={classes.border}
                      >
                        <TestType
                          key={test.id}
                          years_old={data.patient.years_old}
                          sex={data.patient.sex}
                          {...test}
                          test_id={testCode}
                        />
                      </Grid>
                    );
                  } else {
                    return (
                      <div
                        className="cell medium-12 boder_bottom"
                        Key={index + "x"}
                      >
                        <div> falta datos de referencia para una prueba</div>
                      </div>
                    );
                  }
                })}
              </Grid>
            );
          })}

        {data &&
          data.test_values_by_categories &&
          data.test_values_by_categories.sediment &&
          data.test_values_by_categories.sediment.map((group, index) => {
            return (
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} className={classes.group}>
                  {group.name}
                </Grid>
                {group.tests.map((test, index) => {
                  if (test) {
                    return (
                      <Grid
                        item
                        xs={12}
                        container
                        key={test.test_id}
                        spacing={3}
                        className={classes.border}
                      >
                        <TestType
                          key={test.id}
                          years_old={data.patient.years_old}
                          sex={data.patient.sex}
                          {...test}
                          test_id={testCode}
                        />
                      </Grid>
                    );
                  } else {
                    return (
                      <div
                        className="cell medium-12 boder_bottom"
                        Key={index + "x"}
                      >
                        <div> falta datos de referencia para una prueba</div>
                      </div>
                    );
                  }
                })}
              </Grid>
            );
          })}

        {data &&
          data.test_values_by_categories &&
          data.test_values_by_categories.cultivation &&
          data.test_values_by_categories.cultivation.map((group, index) => {
            return (
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} className={classes.group}>
                  {group.name}
                </Grid>
                {group.tests.map((test, index) => {
                  if (test) {
                    return (
                      <Grid
                        item
                        xs={12}
                        container
                        key={test.test_id}
                        spacing={3}
                        className={classes.border}
                      >
                        <TestType
                          key={test.id}
                          years_old={data.patient.years_old}
                          sex={data.patient.sex}
                          {...test}
                          test_id={testCode}
                        />
                      </Grid>
                    );
                  } else {
                    return (
                      <div
                        className="cell medium-12 boder_bottom"
                        Key={index + "x"}
                      >
                        <div> falta datos de referencia para una prueba</div>
                      </div>
                    );
                  }
                })}
              </Grid>
            );
          })}

        {data &&
          data.test_values_by_categories &&
          data.test_values_by_categories.other &&
          data.test_values_by_categories.other.map((group, index) => {
            return (
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} className={classes.group}>
                  {group.name}
                </Grid>
                {group.tests.map((test, index) => {
                  if (test) {
                    if (test.value) {
                      return (
                        <Grid
                          item
                          xs={12}
                          container
                          key={test.test_id}
                          spacing={3}
                          className={classes.border}
                        >
                          <TestType
                            key={test.id}
                            years_old={data.patient.years_old}
                            sex={data.patient.sex}
                            {...test}
                            test_id={testCode}
                          />
                        </Grid>
                      );
                    }
                  } else {
                    return (
                      <div
                        className="cell medium-12 boder_bottom"
                        Key={index + "x"}
                      >
                        <div> falta datos de referencia para una prueba</div>
                      </div>
                    );
                  }
                })}
              </Grid>
            );
          })}
      </Grid>

      <Grid item xs={12}>
        <span className={classes.group}>Observaciones: </span>{" "}
        {data && data.general_obs}
      </Grid>
    </Grid>
  );
};

export default TestVerify;
