import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Container, makeStyles } from "@material-ui/core";
import "./App.css";
import TestVerify from "./pages/TestVerify";
import TestStatus from "./pages/TestStatus";
import TestOrder from "./pages/TestOrder";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const App = () => {
  const classes = useStyles();

  return (
    <Router>
      <div className={classes.root}>
        <Container maxWidth="lg">
          <Switch>
            <Route path="/verify/:testCode">
              <TestVerify />
            </Route>
            <Route path="/status/:testCode">
              <TestStatus />
            </Route>
            <Route path="/order/:testCode">
              <TestOrder />
            </Route>
          </Switch>
        </Container>
      </div>
    </Router>
  );
};

export default App;
