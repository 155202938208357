import React, { PureComponent } from "react";
import { Grid } from "@material-ui/core";

class TypeText extends PureComponent {
  render() {
    const { ...data } = this.props;
    return (
      <Grid
        container
        xs={12}
        className="boder_bottom_text"
        spacing={2}
        direction="row"
      >
        <Grid item xs={6} md={4}>
          <strong>{data.name}</strong>
        </Grid>
        <Grid item xs={6} md={3}>
          {data.value ? data.value : "-"}
        </Grid>
      </Grid>
    );
  }
}

export default TypeText;
