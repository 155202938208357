import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  logo: {
    marginTop: 30,
    marginBottom: 20,
    height: "4.688rem",
  },
  value: {
    color: "#1a1a1a",
    fontWeight: 600,
    fontSize: "1.5rem",
  },
  label: {
    color: "#1a1a1a",
    fontWeight: 400,
    fontSize: "1rem",
  },
}));

const Header = ({ data, testCode, status }) => {
  const classes = useStyles();

  return (
    <>
      <Grid
        item
        xs={12}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <img
          alt="Logo"
          className={classes.logo}
          src={`https://storage.laztin.com/api/containers/logos/download/${data.lab.picture}`}
        />
      </Grid>

      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12} md={3} className={classes.label}>
          Nombre
        </Grid>
        <Grid item xs={12} md={9} className={classes.value}>
          {`${data.patient.full_name || data.patient.names} ${
            data.patient.last_names
          }`}
        </Grid>

        <Grid item xs={12} md={3} className={classes.label}>
          Codigo
        </Grid>
        <Grid item xs={12} md={9} className={classes.value}>
          {testCode}
        </Grid>
        {status === "complete" ? (
          <>
            <Grid item xs={12} md={3} className={classes.label}>
              Fecha de informe
            </Grid>
            <Grid item xs={12} md={9} className={classes.value}>
              {new Date(data.created_at).toLocaleString([], {
                dateStyle: "full",
                timeStyle: "full",
              })}
            </Grid>
          </>
        ) : null}
      </Grid>
    </>
  );
};

export default Header;
