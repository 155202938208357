import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Grid,
  CircularProgress,
  Typography,
  makeStyles,
  Link,
} from "@material-ui/core";
import config from "../../config/data";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  loader: {
    paddingTop: 100,
  },
  physicianTitle: {
    marginTop: 30,
  },
  logo: {
    marginTop: 30,
    marginBottom: 20,
    height: "4.688rem",
  },
  gmap_canvas: { height: 200, width: "100%" },
}));

const TestOrder = () => {
  let { testCode } = useParams();

  const [data, setData] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const classes = useStyles();

  const getData = async (testCode) => {
    try {
      const url = config.api_url + "/order/" + testCode;
      const res = await fetch(url, {
        method: "GET",
        mode: "cors",
        headers: new Headers({
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        }),
      });

      if (res.ok) {
        const data = await res.json();
        setData(data);
      } else if (res.status === 404) {
        setShowMessage(true);
      } else {
        setErrorApi(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData(testCode);
  }, []);

  if (data === null && showMessage) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item className={classes.loader}>
          <Typography variant="h4">No existe la orden "{testCode}"</Typography>
        </Grid>
      </Grid>
    );
  }

  if (data === null && errorApi) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item className={classes.loader}>
          <Typography variant="h4">
            Error de sistema intentelo de nuevo por favor
          </Typography>
        </Grid>
      </Grid>
    );
  }

  if (data === null) {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item className={classes.loader}>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid xs={12}>
        <Typography
          variant="h4"
          align="center"
          className={classes.physicianTitle}
        >
          {data.physician.sex === "male" ? "Dr." : "Dra."}{" "}
          {data.physician.full_name}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          Paciente:
          <strong> {data.patient.full_name}</strong>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          Fecha de Solicitud:
          <strong> {new Date(data.created_at).toLocaleString()}</strong>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">Pruebas Solicitadas</Typography>
      </Grid>
      {data.order.map((test, index) => {
        return (
          <Grid key={"test_" + index} item xs={12}>{`${index + 1}. ${
            test.test
          }`}</Grid>
        );
      })}
      <Grid item xs={12}>
        <Typography variant="h4">Requisitos para Toma de Muestra</Typography>
      </Grid>
      {data.requirements.map((requirement, index) => {
        return (
          <Grid key={"requirement_" + index} item xs={12}>{`${index + 1}. ${
            requirement.requirement
          }`}</Grid>
        );
      })}
      <Grid item xs={12}>
        <Typography variant="h4">Laboratorio Recomendado</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <img
          alt="Logo"
          className={classes.logo}
          src={`https://storage.laztin.com/api/containers/logos/download/${data.recommended_lab.logo}`}
        />
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12}>
          {data.recommended_lab.lab_name}
        </Grid>
        <Grid item xs={12}>
          Envía un mensaje a Laboratorio Xentiz por WhatsApp.
          <strong>
            <Link href={data.recommended_lab.whatsapp}>
              {data.recommended_lab.whatsapp}
            </Link>
          </strong>
        </Grid>
        <Grid item xs={12}>
          {data.recommended_lab.address}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div
          dangerouslySetInnerHTML={{
            __html: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.4145193462878!2d-66.15139838486944!3d-17.391883688075886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x93e373f8e861f457%3A0x530d24dc410738c7!2sLaboratorio%20Xentiz%20-%20Cochabamba!5e0!3m2!1ses!2sbo!4v1632774320494!5m2!1ses!2sbo" width="100%" height="200px" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default TestOrder;
