import React, { PureComponent } from "react";
import { Grid } from "@material-ui/core";

class TypeTextLine extends PureComponent {
  render() {
    const { ...data } = this.props;
    return (
      <Grid item container xs={12} className="boder_bottom_text" spacing={2}>
        <Grid item xs={12}>
          {data.value}
        </Grid>
      </Grid>
    );
  }
}

export default TypeTextLine;
