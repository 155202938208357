import React, { PureComponent } from "react";
import { Grid, Hidden } from "@material-ui/core";

class TypeTextAdd extends PureComponent {
  render() {
    const { ...data } = this.props;

    let vals = null;
    if (data.values) {
      vals = data.values.map((x) => (
        <Grid item key={x} xs={12}>
          {x}
        </Grid>
      ));
    }

    if (vals) {
      return (
        <Grid
          item
          container
          xs={12}
          spacing={2}
          className="boder_bottom_text"
          direction="row"
        >
          <Grid item xs={5} md={4}>
            <strong>{data.name}</strong>
          </Grid>
          <Grid item xs={7} md={4} container>
            {vals}
          </Grid>
          <Hidden mdDown>
            <Grid item md={4}>
              {""}
            </Grid>
          </Hidden>
        </Grid>
      );
    } else {
      return null;
    }
  }
}

export default TypeTextAdd;
